import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Services from "../components/services"
import ContactForm from "../sections/contactForm"

const ServicesPage = () => {
  return (
    <Layout>
      <SEO title="Services" />
      <article>
        <section id="services" className="services">
          <div className="container py-5">
            <h1 className="row justify-content-center dubbelbit">Services</h1>
            <Services />
          </div>
        </section>

        <ContactForm />
      </article>
    </Layout>
  )
}
export default ServicesPage
