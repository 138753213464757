import React from "react"
import PropTypes from "prop-types"
import TeamMemberBio from "./teamMemberBio"
import { withPrefix } from "gatsby"
import Img from "gatsby-image"

const Featurette = ({
  title,
  subtitle,
  image,
  primaryTeamMember,
  secondaryTeamMember,
  html,
  index,
}) => {
  var even = index % 2 === 1
  const isHomePage =
    typeof window !== "undefined" &&
    window.location.pathname === withPrefix("/")
  return (
    <>
      <article className="row featurette">
        <div className={"col-lg-8 " + (even ? "order-lg-2" : null)}>
          <h2 className="featurette-heading">
            {title}. <span className="text-muted">{subtitle}.</span>
          </h2>
          <section className="lead">
            <div dangerouslySetInnerHTML={{ __html: html }}></div>
            {!isHomePage && (
              <div>
                {primaryTeamMember !== null && (
                  <TeamMemberBio
                    id={primaryTeamMember.childMarkdownRemark.name}
                    name={
                      primaryTeamMember.childMarkdownRemark.frontmatter.name
                    }
                    title={
                      primaryTeamMember.childMarkdownRemark.frontmatter.title
                    }
                    profileImage={
                      primaryTeamMember.childMarkdownRemark.frontmatter
                        .profileImage
                    }
                    excerpt={primaryTeamMember.childMarkdownRemark.excerpt}
                  />
                )}
                {secondaryTeamMember !== null && (
                  <TeamMemberBio
                    id={secondaryTeamMember.childMarkdownRemark.name}
                    name={
                      secondaryTeamMember.childMarkdownRemark.frontmatter.name
                    }
                    title={
                      secondaryTeamMember.childMarkdownRemark.frontmatter.title
                    }
                    profileImage={
                      secondaryTeamMember.childMarkdownRemark.frontmatter
                        .profileImage
                    }
                    excerpt={secondaryTeamMember.childMarkdownRemark.excerpt}
                  />
                )}
              </div>
            )}
          </section>
        </div>
        <div className="col-lg-4">
          {image !== null && (
            <Img
              fluid={image.childImageSharp.fluid}
              alt={title}
              className="featurette-image"
            />
          )}
          {image === null && (
            <figure className="featurette-image">
              <svg
                className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                width="500"
                height="500"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
                role="img"
                aria-label="Placeholder: 500x500"
              >
                <title>Placeholder</title>
                <rect width="100%" height="100%" fill="#eee"></rect>
                <text x="50%" y="50%" fill="#aaa" dy=".3em">
                  500x500
                </text>
              </svg>
            </figure>
          )}
        </div>
      </article>
      <hr className="featurette-divider"></hr>
    </>
  )
}

Featurette.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  image: PropTypes.object,
  primaryTeamMember: PropTypes.object,
  secondaryTeamMember: PropTypes.object,
  html: PropTypes.string.isRequired,
  index: PropTypes.number,
}

export default Featurette
