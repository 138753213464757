import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

const TeamMemberBio = ({ name, title, excerpt, profileImage }) => {
  return (
    <>
      <div className="card mb-3">
        <div className="author-profile d-flex flex-nowrap no-gutters">
          <div>
            <Img
              fluid={profileImage.childImageSharp.fluid}
              className="author-profile-image rounded align-self-end mr-2"
              alt={name}
            />
          </div>
          <div>
            <div className="card-body p-0">
              <h6 className="card-title m-0 mt-1">
                {name} - <span className="text-info">{title}</span>
              </h6>
              <p className="card-text m-0 author-bio">{excerpt}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

TeamMemberBio.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  profileImage: PropTypes.object.isRequired,
}

export default TeamMemberBio
