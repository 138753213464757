import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Featurette from "./featurette"

const Services = () => {
  const services = useStaticQuery(graphql`
    query Services {
      allMarkdownRemark(
        sort: { fields: frontmatter___order }
        filter: { frontmatter: { type: { eq: "service" } } }
      ) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              title
              subtitle
              image {
                ...FeaturetteImage
              }
              primaryTeamMember {
                ...TeamMember
              }
              secondaryTeamMember {
                ...TeamMember
              }
            }
            excerpt(format: HTML)
          }
        }
      }
    }
  `)
  return (
    <>
      {services.allMarkdownRemark.edges.map(({ node }, index) => (
        <Featurette
          key={node.id}
          title={node.frontmatter.title}
          subtitle={node.frontmatter.subtitle}
          image={node.frontmatter.image}
          primaryTeamMember={node.frontmatter.primaryTeamMember}
          secondaryTeamMember={node.frontmatter.secondaryTeamMember}
          html={node.excerpt}
          index={index}
        />
      ))}
    </>
  )
}

export default Services
